/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import styled, { css } from 'styled-components';

import Header from './navigation/header';
import Footer from './footer/footer';
import { NavLabel, ButtonText } from '../theme/typography';
import Container from './container';
import BellSVG from '../images/icons/bell.inline.svg';

import { GlobalStyle } from '../theme/global-style';

const fullScreenPaths = ['/director-setup/', '/director-setup'];
const simpleFooterPaths = ['/release', '/release/'];
const LBBPaths = ['/', '/products/tv-commercial/', '/products/online-video/'];

const containsStringNotAtEnd = (url, string) => {
  // Find the index of 'release/' in the string
  const index = url.indexOf(string);

  // Check if string is found and it is not at the end of the string
  // The substring after string should have at least one more character
  return index !== -1 && index + string.length < url.length;
};

const NewsBanner = styled.div`
  display: flex;

  background-color: var(--white);
  color: var(--blue1);
  text-transform: uppercase;
  padding: 8px 16px;
  border-bottom: 2px solid var(--accent);
  max-width: 100%;
  overflow: hidden;
`;

const BannerContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100dvw;
  gap: 16px;
`;

const Pill = styled(NavLabel)`
  display: flex;
  padding: 0 16px;
  padding-top: 5px;
  padding-bottom: 3px;
  border-radius: 16.5px;
  white-space: nowrap;
  color: var(--blue1);
  background: var(--accent);
  align-items: center;
`;

const BellIconSVG = styled(BellSVG)`
  height: 16px;
  width: 16px;
  stroke: var(--blue1);
  margin-top: -2px;
  margin-right: 8px;
`;

const StyledNavLabel = styled(NavLabel)`
  max-width: 60%;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  if (
    fullScreenPaths.includes(children.props.location.pathname) ||
    containsStringNotAtEnd(children.props.location.pathname, 'release/')
  ) {
    return <>{children}</>;
  }

  if (LBBPaths.includes(children.props.location.pathname)) {
    return (
      <>
        <Header siteTitle={data.site.siteMetadata.title}>
          <a href='/news/immortal-awards-2024'>
            <NewsBanner>
              {/* <Container> */}
              <BannerContent>
                <Pill>
                  <BellIconSVG />
                  News
                </Pill>
                <StyledNavLabel>
                  Rubberduck ranked #1 PRODUCTION COMPANY in IRELAND at lbb
                  immortal awards
                </StyledNavLabel>
              </BannerContent>
              {/* </Container> */}
            </NewsBanner>
          </a>
        </Header>
        <GlobalStyle />
        <main>{children}</main>
        <Footer
          simple={simpleFooterPaths.includes(children.props.location.pathname)}
        />
      </>
    );
  }
  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <GlobalStyle />
      <main>{children}</main>
      <Footer
        simple={simpleFooterPaths.includes(children.props.location.pathname)}
      />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
